import React from "react";
import CmtVertical from "../../../../../@coremat/CmtNavigation/Vertical";
import PerfectScrollbar from "react-perfect-scrollbar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IntlMessages from "../../../../utils/IntlMessages";
import { Dashboard, Ballot, Book, Person, Message, MenuBook } from "@material-ui/icons";
import usePermissions from "app/hooks/usePermissions";

const useStyles = makeStyles((theme) => ({
  perfectScrollbarSidebar: {
    height: "100%",
    transition: "all 0.3s ease",
    ".Cmt-sidebar-fixed &, .Cmt-Drawer-container &": {
      height: "calc(100% - 167px)",
    },
    ".Cmt-modernLayout &": {
      height: "calc(100% - 72px)",
    },
    ".Cmt-miniLayout &": {
      height: "calc(100% - 91px)",
    },
    ".Cmt-miniLayout .Cmt-sidebar-content:hover &": {
      height: "calc(100% - 167px)",
    },
  },
}));

const SideBar = () => {
  const classes = useStyles();
  const { applyPermissions, permissions } = usePermissions();
  console.log("==============================>");
  console.log(permissions);
  console.log(permissions.includes("dashboard.view"));
  const navigationMenus = [
    ((localStorage.getItem("role") != "dialog") ? {
      name: <IntlMessages id={"sidebar.dashboard"} />,
      type: "item",
      icon: <Dashboard />,
      link: "/dashboard",
    }:""),
    ...applyPermissions([
      (localStorage.getItem("role") === "admin"? {
        key: "lottery.view",
        value: {
          name: <IntlMessages id={"sidebar.lotteries"} />,
          type: "item",
          icon: <Book />,
          link: "/lotteries",
        },
      }: ""),
      (permissions.includes("draw.view") ? {
        key: "draw.view",
        value: {
          name: <IntlMessages id={"sidebar.draws"} />,
          type: "item",
          icon: <Ballot />,
          link: "/draws",
        },
      }:""),
      (permissions.includes("user.register") ? {
        key: "user.register",
        value: {
          name: <IntlMessages id={"sidebar.crm"} />,
          type: "item",
          icon: <Person />,
          link: "/users",
        }
      }:""),
      (permissions.includes("download.tickets") ? {
        key: "download.tickets",
        value: {
          name: <IntlMessages id={"sidebar.reports"} />,
          type: "item",
          icon: <MenuBook />,
          link: "/reports",
        }
      }:""),
      (permissions.includes("smslog.view") ? {
        key: "smslog.view",
        value: {
          name: <IntlMessages id={"sidebar.sms"} />,
          type: "item",
          icon: <Message />,
          link: "/sms",
        }
      }:""),
      (permissions.includes("smslog.view") ? {
        key: "smslog.view",
        value: {
          name: <IntlMessages id={"sidebar.biller"} />,
          type: "item",
          icon: <Person />,
          link: "/biller",
        }
      }:""),
      (permissions.includes("contactUsMsg.view") ? {
        key: "contactUsMsg.view",
        value: {
          name: <IntlMessages id={"sidebar.contactsms"} />,
          type: "item",
          icon: <Message />,
          link: "/contactsms",
        }
      }:""),
    ]),
  ];

  return (
      <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
        <CmtVertical menuItems={navigationMenus} />
      </PerfectScrollbar>
  );
};

export default SideBar;
